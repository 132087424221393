const menuItems = {
  category: [
    {
      id: 'AddressLabelConfig',
      label: 'Address Label Config',
      action: [
        { id: 'address-label', label: 'Address Label' },
        { id: 'demat-documents', label: 'Dematerialized Documents' },
        { id: 'tracking', label: 'Tracking' },
        { id: 'internal-request', label: 'Internal Request' },
        { id: 'pickup-request', label: 'Pickup Request' },
      ],
    },
    {
      id: 'ApiClient',
      label: 'Api Client',
      action: [
        { id: 'api-call', label: 'Api Call' },
        { id: 'tms-api', label: 'TMS Api Call' },
      ],
    },
    {
      id: 'CarrierCredential',
      label: 'Carrier Credential',
      action: [{ id: 'carrier-router', label: 'Carrier Router' }],
    },
    {
      id: 'FileSystemConfig',
      label: 'FTP Config',
      action: [{ id: 'ftp-call', label: 'FTP Call' }],
    },
    {
      id: 'FtpReportConfig',
      label: 'Ftp Report Config',
      action: [{ id: 'tracking', label: 'Tracking' }],
    },
    {
      id: 'InvoicesConfig',
      label: 'InvoicesConfig',
      action: [{ id: 'invoice-export', label: 'Invoice Export' }],
    },
    {
      id: 'InsuranceProviderConfig',
      label: 'InsuranceProviderConfig',
      action: [{ id: 'insurance-provider', label: 'Insurance Provider' }],
    },
    {
      id: 'LabelCredential',
      label: 'Label Credential',
      action: [
        { id: 'pallet', label: 'Pallet' },
        { id: 'label-credential-refresh-token', label: 'OAuth' },
      ],
    },
    {
      id: 'OrderStateNotification',
      label: 'OrderStateNotification',
      action: [
        { id: 'send-sms', label: 'SMS Sent' },
        { id: 'url-shortener', label: 'Url Shortener' },
        { id: 'order-state-notification', label: 'Email Sent' },
      ],
    },
    {
      id: 'ReportConfig',
      label: 'Report Config',
      action: [
        { id: 'report-config-api-call', label: 'Api Call' },
        { id: 'report-config-edi-call', label: 'Edi Call' },
        { id: 'report-config-ftp-call', label: 'Ftp Call' },
      ],
    },
    {
      id: 'ReturnLabelConfig',
      label: 'Return Label Config',
      action: [
        { id: 'return-label', label: 'Return Label' },
        { id: 'internal-request', label: 'Internal Request' },
      ],
    },
    {
      id: 'ServiceCredential',
      label: 'Service Credential',
      action: [
        { id: 'stock-deliverer', label: 'Stock Deliverer' },
        { id: 'order-deliverer', label: 'Order Deliverer' },
        { id: 'shipment-deliverer', label: 'Shipment Deliverer' },
        { id: 'order-updater', label: 'Order Updater' },
        { id: 'supply-capsule-updater', label: 'SupplyCapsule Updater' },
        { id: 'order-fetcher', label: 'Order Fetcher' },
        { id: 'product-fetcher', label: 'Product Fetcher' },
        { id: 'return-order-fetcher', label: 'Return Order Fetcher' },
        { id: 'return-order-updater', label: 'Return Order Updater' },
        { id: 'common-fetcher', label: 'Common Fetcher' },
        { id: 'other-action', label: 'Other actions' },
        { id: 'feed', label: 'Feed' },
      ],
    },
    {
      id: 'SmtpCredential',
      label: 'Smtp Credential',
      action: [{ id: 'email-sent', label: 'Email Sent' }],
    },
    {
      id: 'UpdateHook',
      label: 'Update Hook',
      action: [{ id: 'update-hook', label: 'Update Hook' }],
    },
  ],
};
export default menuItems;
