import React from 'react';

import { useTranslation } from 'react-i18next';

export default function Select(props) {
  const { t } = useTranslation('translation');

  return (
    <select
      className={'selector ' + props.className}
      style={{ width: props.width }}
      onChange={props.handleChange}
      value={props.selection}
      aria-label={props['aria-label']}
      data-testid={props.id + '_selector'}
    >
      {props.label !== null ? (
        <option value="" disabled>
          {t(props.label)}
        </option>
      ) : null}
      {props.options.map((option) => (
        <option value={option.id} key={option.id}>
          {t(option.label)}
        </option>
      ))}
    </select>
  );
}
