import momentTz from 'moment-timezone';
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {
  initializeAxiosMockAdapter,
  isMockEnabled,
} from './mocks/mock.config.js';

const OMS_URL = process.env.REACT_APP_OMS_SERVER;

const oms_server = axios.create({
  baseURL: OMS_URL,
  headers: { 'X-ActiveModelSerializers': 1 },
});
/* istanbul ignore if */
if (isMockEnabled()) {
  initializeAxiosMockAdapter(oms_server);
}

export default oms_server;

function goToLogin() {
  const current_location = window.location.href;
  window.location.href =
    OMS_URL + 'users/sign_in?checklogin=true&goto=' + current_location;
}

export const refreshAuthLogic = (failedRequest) => {
  // Session is dead, go to OMS login
  goToLogin();
  return Promise.resolve();
};

createAuthRefreshInterceptor(oms_server, refreshAuthLogic, {
  statusCodes: [403, 401],
});
