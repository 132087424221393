import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Navbar(props) {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <nav
      className="navbar"
      role="navigation"
      aria-label="main navigation"
      data-testid="navbar"
    >
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
          <img
            src="/assets/logos/sbug_logo.png"
            alt="logo"
            width="85"
            className="mainLogo"
          />
        </a>
        <div className="navbar-item align-end">
          <a href={process.env.REACT_APP_OMS_SERVER + 'users/sign_out'}>
            <img
              className="icon-16"
              alt={t('menu.logout')}
              src="/assets/icons/power-on-off.svg"
            />
          </a>
        </div>
      </div>
    </nav>
  );
}
