import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import menuFilterItems from './menuFilterItems';

import mockedMenu from './mocks/menuItems';

import Select from './components/Select/Select';

import { useTranslation } from 'react-i18next';

function MenuItem(props) {
  const { t } = useTranslation('translation');
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [selectedOption, setSelectedOption] = useState(
    queryParams.get(props.param) || ''
  );
  const [ready, setReady] = useState(false);

  useEffect(() => {
    /* istanbul ignore if */
    if (props.dependency) {
      if (ready) {
        const newQueryParams = queryParams;
        if (newQueryParams.has(props.param)) {
          newQueryParams.delete(props.param);
          history.push({ search: newQueryParams.toString() });
        }
        setSelectedOption('');
      } else {
        setReady(true);
      }
    }
  }, [queryParams.get(props.dependency)]);

  function handleChange(evt) {
    const newQueryParams = queryParams;
    const selectedValue = evt.target.value;
    if (newQueryParams.has(props.param)) {
      newQueryParams.delete(props.param);
    }
    if (selectedValue) {
      newQueryParams.append(props.param, selectedValue);
    }

    setSelectedOption(selectedValue);
    history.push({ search: newQueryParams.toString() });
  }

  function renderSwitch(param) {
    switch (param) {
      case 'list':
        let options = mockedMenu[props.param];
        if (props.dependency) {
          const dependencyValue = queryParams.get(props.dependency);
          if (!dependencyValue) {
            return null;
          }
          options = mockedMenu[props.dependency].find((category) => {
            return category.id === dependencyValue;
          })[props.param];
        }
        return (
          <Select
            options={options}
            width="100%"
            selection={selectedOption}
            label={''}
            handleChange={handleChange}
            id={props.label}
          />
        );
      case 'numeric':
        return (
          <input
            type="number"
            className="selector"
            style={{ width: '100%' }}
            value={selectedOption}
            onChange={handleChange}
            data-testid={props.label + '_selector'}
            id={props.label}
          />
        );
      default:
        return null;
    }
  }

  return (
    <li>
      <div>
        <h1>{t(props.label)}</h1>
        {renderSwitch(props.type)}
        <span className="hint">
          {!renderSwitch(props.type)
            ? t('common.pleaseSelect', { thing: t('menu.' + props.dependency) })
            : null}
        </span>
      </div>
    </li>
  );
}

export default function Menu(props) {
  const { t } = useTranslation('translation');
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  let initialContextFilter = '';
  if (queryParams.has('order_id')) {
    initialContextFilter = 'order_id';
  } else if (queryParams.has('product_id')) {
    initialContextFilter = 'product_id';
  }
  const currentContext = queryParams.keys();
  const [contextFilter, setContextFilter] = useState(initialContextFilter);
  const [contextValue, setContextValue] = useState(
    queryParams.get(contextFilter) || ''
  );

  const contextOptions = [
    { id: null, label: '' },
    { id: 'order_id', label: t('context.order_id') },
    { id: 'product_id', label: t('context.product_id') },
  ];

  function handleContextFilterValue(evt) {
    const newQueryParams = queryParams;
    const selectedValue = evt.target.value;
    if (newQueryParams.has(contextFilter)) {
      newQueryParams.delete(contextFilter);
    }
    if (selectedValue) {
      newQueryParams.append(contextFilter, selectedValue);
    }

    setContextValue(selectedValue);
    history.push({ search: newQueryParams.toString() });
  }

  function changeContextFilter(evt) {
    const newQueryParams = queryParams;
    if (newQueryParams.has(contextFilter)) {
      newQueryParams.delete(contextFilter);
    }
    history.push({ search: newQueryParams.toString() });
    setContextFilter(evt.target.value);

    setContextValue('');
    const contextValue = document.getElementById('context_value_sel');
    if (contextValue) {
      contextValue.value = '';
      contextValue.focus();
    }
  }

  return (
    <div className="menu" data-testid="menu">
      <ul className="menu-list">
        {menuFilterItems.map((item) => (
          <MenuItem
            label={item.label}
            param={item.param}
            type={item.type}
            dependency={item.dependency}
            key={item.param}
          />
        ))}

        <li>
          <div>
            <h1 className="spaced">{t('menu.context')}</h1>
            <Select
              options={contextOptions}
              width="100%"
              selection={contextFilter}
              label={null}
              handleChange={changeContextFilter}
              id="context_field"
              className={contextFilter ? 'vertical-compound-start' : null}
            />
            {contextFilter ? (
              <input
                type="text"
                autoFocus
                className="selector vertical-compound-end"
                style={{ width: '100%' }}
                value={contextValue}
                onChange={handleContextFilterValue}
                data-testid="context_value_selector"
                id="context_value_sel"
              />
            ) : null}
          </div>
        </li>
      </ul>
    </div>
  );
}
