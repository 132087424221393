import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import API from '../../../API';
import StatusBadge from '../../StatusBadge/StatusBadge';
import ContextBlock from '../../ContextBlock/ContextBlock';
import { CallDetails } from '../../ContentCard/CallItem';
import Loader from '../../Loader/Loader';

export default function FullscreenCall(props) {
  const { t } = useTranslation();
  let { callId } = useParams();
  const location = useLocation();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setData(null);
      setLoading(true);
      const result = await API.get('/debug_files/' + callId, {
        withCredentials: true,
      });
      setData(result.data.debug_file);
      setLoading(false);
    }
    fetchData();
  }, [props.category, props.service, props.action]);

  function Content() {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <div>
          <div className="big-header">
            <div className="title">{data.service}</div>
            <div className="subtitle"> {data.service_id}</div>
          </div>

          <div className="small-header"> {data.service_action}</div>
          <div className="small-header">
            <StatusBadge status={data.response_document?.response_code} />
            <span className="tag is-dark">{data.request_document?.verb}</span>
            <div className="call-url">{data.request_document?.url}</div>
            <span className="subtitle push-right">
              {moment(data.call_timestamp).format('L LTS')}
              <span class="tiny-text">({t('common.localTime')})</span>
            </span>
          </div>
          <ContextBlock context={data.service_context} />
          <CallDetails data={data} expanded="true" />
        </div>
      );
    }
  }

  return (
    <div className="FullscreenCall CallItem">
      <div className="go-back">
        <Link to={'/' + location.search}>
          <span role="button" className="subtitle">
            <img className="icon-40" src="assets/icons/arrow-left.svg" />
            {t('fullscreen.goback')}
          </span>
        </Link>
      </div>
      <Content />
    </div>
  );
}
