import React from 'react';

export default function Loader(props) {
  const style = {
    height: 'calc(100% - 150px)',
    width: '100%',
    display: 'flex',
  };
  return (
    <div style={style}>
      <div className="loader"></div>
    </div>
  );
}
