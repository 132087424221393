import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './styles.scss';
import SBug from './SBug';
import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="Loading Translations...">
      <SBug />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);
