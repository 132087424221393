/* Ordering will be respected in UI */
const menuItems = [
  {
    label: 'menu.category',
    param: 'category',
    type: 'list',
  },
  {
    label: 'menu.service',
    param: 'service',
    type: 'numeric',
  },
  {
    label: 'menu.action',
    param: 'action',
    type: 'list',
    dependency: 'category',
  },
];

export default menuItems;
