import React, { useState } from 'react';
import ContentPane from './ContentPane';
import Menu from './Menu';
import Navbar from './Navbar';
import { BrowserRouter as Router } from 'react-router-dom';
import moment from 'moment';

/* Communication Layer of the application */
export default function SBug() {
  const [state, setState] = useState({
    startDate: moment().subtract(7, 'day'),
    endDate: moment(),
  });

  /*function changeDates(start, end) {
    setState({
      startDate: start,
      endDate: end,
    });
  }*/

  return (
    <div className="App">
      <Router>
        <Navbar />
        <div className="full_content">
          <span className="is-hidden-mobile elevation-20">
            <Menu />
          </span>
          <div className="main-pane withmenu">
            <ContentPane start={state.startDate} end={state.endDate} />
          </div>
        </div>
      </Router>
    </div>
  );
}
