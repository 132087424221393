import React from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import NoAccess from './components/ViewComponents/Access/NoAccess';

import CallList from './components/ViewComponents/CallList/CallList';
import FullscreenCall from './components/ViewComponents/FullscreenCall/FullscreenCall';

import { useTranslation } from 'react-i18next';

const mandatoryFilters = ['category', 'service', 'action'];

function SplashScreen() {
  const { t } = useTranslation('translation');
  return (
    <div className="splashScreen">
      <div className="title">{t('common.splashScreenTitle')}</div>
      <div className="splashScreenIcon">
        <img src="/assets/animated_icons/server-outline.png" alt="" />
      </div>
    </div>
  );
}

export default function ContentPane(props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const hasSufficientFilters = mandatoryFilters.every((filter) => {
    return queryParams.has(filter);
  });

  let context = null;
  if (queryParams.has('product_id')) {
    context = { product_id: queryParams.get('product_id') };
  } else if (queryParams.has('order_id')) {
    context = { order_id: queryParams.get('order_id') };
  }

  if (queryParams.get('skipped_login')) {
    return <Redirect to="no-access" />;
  } else {
    return (
      <div className={'ContentPane columns'}>
        <Switch>
          <Route exact path="/">
            {hasSufficientFilters ? (
              <CallList
                category={queryParams.get('category')}
                service={queryParams.get('service')}
                action={queryParams.get('action')}
                application={queryParams.get('application_id')}
                context={context}
              />
            ) : (
              <SplashScreen />
            )}
          </Route>
          <Route path="/no-access">
            <NoAccess {...props} />
          </Route>
          <Route path="/:callId">
            <FullscreenCall />
          </Route>
        </Switch>
      </div>
    );
  }
}
