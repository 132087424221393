import React, { useState, useEffect } from 'react';
import ReactJson from 'react-json-view';
import { useTranslation } from 'react-i18next';

const ReactJsonConfig = {
  name: false,
  indentWidth: 2,
  theme: 'solarized',
  iconStyle: 'square',
  collapsed: 'true',
  collapseStringsAfterLength: 100,
};

function ContextTag(props) {
  if (
    props.context[props.field] === null ||
    props.context[props.field] === undefined
  ) {
    return null;
  }
  const type = typeof props.context[props.field];
  switch (type) {
    case 'number':
    case 'string':
    case 'boolean':
      return (
        <div className="control" key={props.field}>
          <div className="tags has-addons">
            <span className="tag is-dark">{props.field}</span>
            <span className="tag is-info">
              {props.context[props.field].toString()}
            </span>
          </div>
        </div>
      );
    default:
      return (
        <div className="control" key={props.field}>
          <div className="tags complex-tags has-addons">
            <span className="tag is-dark">{props.field}</span>
            <div className="tag complex is-dark">
              <ReactJson
                {...ReactJsonConfig}
                src={props.context[props.field]}
              />
            </div>
          </div>
        </div>
      );
  }
}

export default function ContextBlock(props) {
  const { t, i18n } = useTranslation();
  if (props.context) {
    return (
      <div className="ContextBlock">
        {t('common.context')}
        <div className="field is-grouped is-grouped-multiline">
          {Object.keys(props.context).map((field) => (
            <ContextTag field={field} key={field} context={props.context} />
          ))}
        </div>
      </div>
    );
  } else {
    return null;
  }
}
