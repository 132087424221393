/* istanbul ignore file */

import debugServiceCalls from './debugServiceCalls.json';
import MockAdapter from 'axios-mock-adapter';
import appConfig from '../config';
import moment from 'moment';

const NB_BONUS_CALLS = 250;

const filledCalls = debugServiceCalls.concat(
  [...Array(NB_BONUS_CALLS)].map((_void, index) => {
    return {
      id: index + 100,
      service_context: {
        aa_aaa: 'aaaaa aa',
        bbbb_bb: 'bbb bbbb',
      },
      verb: 'XXX',
      category: 'category 1',
      service: 'service 1',
      action: 'action 1',
      call_url: 'http://short.url/blabla',
      response_code: 'XXX',
      req_headers: {
        abc: 'def',
        ghi: 'jkl',
        bla: 'blop',
        blablabulot: ['a', 'b', 3, 'd', 42, 'abcdef'],
      },
      resp_headers: { abc: 'def', ghi: 'jkl' },
      request_document_url: 'https://s3.path.of/request_file.ext',
      response_document_url: 'https://s3.path.of/response_file.ext',
      call_timestamp: moment(1624004805000 - index * 10000).format(),
    };
  })
);

export const isMockEnabled = () => {
  return process.env.REACT_APP_MOCK_ENABLED === 'true';
};

const mocktimer = process.env.REACT_APP_MOCK_TIMER || 2000;

export const initializeAxiosMockAdapter = (instance) => {
  const mock = new MockAdapter(instance, {
    onNoMatch: 'throwException',
    delayResponse: mocktimer,
  });
  mock
    .onGet(/\/debug_files\/\w+\/\d+\/\w+/)
    .reply((config) => getServiceCalls(config));
  mock.onGet(/\/debug_files\/\d+/).reply((config) => getServiceCall(config));
};

export const getServiceCalls = (config) => {
  const first = config.params.offset || 0;
  const filteredCalls = filledCalls.filter((call) => {
    return moment(call.call_timestamp).isBetween(
      config.params.call_timestamp_start,
      config.params.call_timestamp_end
    );
  });
  return [
    200,
    { debug_files: filteredCalls.slice(first, first + appConfig.pageSize) },
  ];
};

export const getServiceCall = (config) => {
  const id = extractIdPathParamFromUrl(config);
  const debugServiceCall = filledCalls.find((call) => call.id === id);
  return [200, { debug_file: debugServiceCall }];
};

const extractIdPathParamFromUrl = (config) => {
  return config.url.split('/').pop();
};
