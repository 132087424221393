import React from 'react';
import StatusMapping from '../../StatusMapping';

export default function StatusBadge(props) {
  let customClass = 'is-info';
  switch (StatusMapping[(props.status + '')[0]]) {
    case 'bad':
      customClass = 'is-danger';
      break;
    case 'good':
      customClass = 'is-success';
      break;
    case 'warn':
      customClass = 'is-warning';
  }
  return <span className={`tag ${customClass}`}>{props.status}</span>;
}
