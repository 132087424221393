import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../API';
import CallItem from '../../ContentCard/CallItem';
import Loader from '../../Loader/Loader';
import config from '../../../config';

import {
  HORIZONTAL_ORIENTATION,
  VERTICAL_ORIENTATION,
} from 'react-dates/lib/constants';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import moment from 'moment';

const datepicker_settings = {
  firstDayOfWeek: 1,
  hideKeyboardShortcutsPanel: true,
  isOutsideRange: () => false,
};

export default function CallList(props) {
  const { t } = useTranslation('translation');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [paginated, setPaginated] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days'));
  const [endDate, setEndDate] = useState(moment());
  const [focusedInput, setFocusedInput] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setPaginated(false);
    setCurrentPage(1);
    setOffset(0);
  }, [
    props.category,
    props.service,
    props.action,
    props.application,
    props.context,
  ]);

  useEffect(() => {
    async function fetchData() {
      setData(null);
      setLoading(true);

      let controllerToUse = 'debug_files';
      if (props.application) {
        controllerToUse = 'debug_file_developers';
      }

      const result = await API.get(
        `/${controllerToUse}/${props.category}/${props.service}/${props.action}?oauth_application_id=${props.application}`,
        {
          withCredentials: true,
          params: {
            offset: offset,
            ...props.context,
            call_timestamp_start: startDate?.startOf('day').toISOString(),
            call_timestamp_end: endDate?.endOf('day').toISOString(),
          },
        }
      );
      if (offset === 0 && result.data.debug_files.length >= config.pageSize) {
        setPaginated(true);
      }

      setData(result.data.debug_files);
      setLoading(false);
    }
    fetchData();
  }, [
    props.category,
    props.service,
    props.action,
    props.context,
    offset,
    startDate,
    endDate,
  ]);

  function previousPage(disabled) {
    if (!disabled && !loading) {
      setOffset(offset - config.pageSize);
      setCurrentPage(parseInt(currentPage) - 1);
    }
  }

  function nextPage(disabled) {
    if (!disabled && !loading) {
      setOffset(offset + config.pageSize);
      setCurrentPage((parseInt(currentPage) || 1) + 1);
    }
  }

  function pageEvent(evt) {
    let page = evt.target.value;
    if (page !== '' && (!parseInt(page) || page < 1)) {
      page = 1;
    }
    setCurrentPage(page);
    setOffset(((page || 1) - 1) * config.pageSize);
  }

  const isFirstPage = offset === 0;
  const isLastPage = data && data.length < config.pageSize;

  return (
    <>
      <div className="filterbar" title="filterbar" key="filterbar">
        <span className="dateRange">
          <DateRangePicker
            {...datepicker_settings}
            startDate={startDate}
            startDatePlaceholderText={t('datepicker.startlabel')}
            startDateId="start_date_id"
            endDate={endDate}
            endDatePlaceholderText={t('datepicker.endlabel')}
            orientation={HORIZONTAL_ORIENTATION}
            endDateId="end_date_id"
            onDatesChange={({ startDate, endDate }) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }}
            focusedInput={focusedInput}
            displayFormat={() => 'DD/MM/YYYY'}
            onFocusChange={(newFocusedInput) =>
              setFocusedInput(newFocusedInput)
            }
            renderMonthElement={({ month }) => {
              return moment(month).format('MMMM YYYY');
            }}
          />
        </span>
        {paginated ? (
          <span className="pagination-bar" title="pagination-bar">
            <span
              onClick={() => previousPage(isFirstPage)}
              className={isFirstPage ? 'disabled' : ''}
            >
              <img
                src="assets/icons/arrow-left.svg"
                alt={t('common.previousPage')}
                title={t('common.previousPage')}
              />
            </span>
            <span className="page-selector">
              {t('common.page')}
              <input
                data-testid="page-selector"
                type="number"
                min="0"
                value={currentPage}
                onChange={pageEvent}
              />
            </span>
            <span
              onClick={() => nextPage(isLastPage)}
              className={isLastPage ? 'disabled' : ''}
            >
              <img
                src="assets/icons/arrow-right.svg"
                alt={t('common.nextPage')}
                title={t('common.nextPage')}
              />
            </span>
          </span>
        ) : null}
      </div>
      {loading ? (
        <Loader key="loader" />
      ) : (
        <div key="callList" className="column is-full">
          {data.map((datum) => (
            <CallItem data={datum} key={datum.id} />
          ))}
        </div>
      )}
    </>
  );
}
