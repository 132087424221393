import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import ReactJson from 'react-json-view';
import StatusBadge from '../StatusBadge/StatusBadge';
import ContextBlock from '../ContextBlock/ContextBlock';
import moment from 'moment';
import 'moment/locale/fr';
import { useTranslation } from 'react-i18next';

const ReactJsonConfig = {
  name: false,
  indentWidth: 2,
  theme: 'solarized',
  iconStyle: 'square',
  collapseStringsAfterLength: 100,
};

function DownloadButton(props) {
  const { t, i18n } = useTranslation();
  if (props.link) {
    return (
      <a href={props.link} target="_blank" className="push-right">
        <button className="button is-small ml-1">
          <span>
            {props.titleKey
              ? t(props.titleKey, { index: props.titleIndex })
              : t('common.downloadFile')}
          </span>
          <span className="icon-16">
            <img src="assets/icons/download.svg" />
          </span>
        </button>
      </a>
    );
  }
  return null;
}

export function CallDetails(props) {
  const { t, i18n } = useTranslation();

  const details = {};
  if (
    props.data.request_document?.headers ||
    props.data.request_document?.params
  ) {
    details.request = {};
    if (props.data.request_document.headers) {
      details.request.headers = props.data.request_document.headers;
    }
    if (props.data.request_document.params) {
      details.request.params = props.data.request_document.params;
    }
  }
  if (
    props.data.response_document?.headers ||
    props.data.response_document?.params ||
    props.data.response_document?.error_message
  ) {
    details.response = {};
    if (props.data.response_document.headers) {
      details.response.headers = props.data.response_document.headers;
    }
    if (props.data.response_document.params) {
      details.response.params = props.data.response_document.params;
    }
    if (props.data.response_document.error_message) {
      details.response.error_message =
        props.data.response_document.error_message;
    }
  }
  if (props.expanded) {
    return (
      <div className="columns ContentCard-details">
        <div className="fullwidth">
          {props.data.additional_documents?.length ? (
            <>
              <span className="title m-1">{t('common.additional_docs')}</span>
              {props.data.additional_documents.map((doc, index) => (
                <DownloadButton
                  titleKey={'common.additional_doc'}
                  titleIndex={index + 1}
                  link={doc.document_url}
                />
              ))}
            </>
          ) : null}
        </div>
        <div className="column m-1">
          <div className="title mb-2 details-header">
            {t('common.request')}
            <DownloadButton link={props.data.request_document?.document_url} />
          </div>
          {details.request ? (
            <ReactJson {...ReactJsonConfig} src={details.request} />
          ) : (
            t('headers.noRequest')
          )}
        </div>

        <div className="column m-1">
          <div className="title mb-2 details-header">
            {t('common.response')}
            <DownloadButton link={props.data.response_document?.document_url} />
          </div>
          {details.response ? (
            <ReactJson {...ReactJsonConfig} src={details.response} />
          ) : (
            t('headers.noResponse')
          )}
        </div>
      </div>
    );
  }
  return null;
}

export default function CallItem(props) {
  const [expanded, setExpanded] = useState(false);
  const [fullData, setFullData] = useState(false);
  const { t, i18n } = useTranslation();
  const location = useLocation();

  moment.locale(i18n.language);

  function toggleExpanded() {
    setExpanded(!expanded);
  }

  return (
    <div className="CallItem" key={props.data.id}>
      <div className={'ContentCard ' + (expanded ? 'expanded' : '')}>
        <div className="ContentCard-header">
          <span role="button" onClick={toggleExpanded}>
            <img
              src={
                expanded
                  ? 'assets/icons/mini-chevron-up.svg'
                  : 'assets/icons/mini-chevron-down.svg'
              }
              alt={t('common.toggleDetails')}
            />
          </span>
          <StatusBadge status={props.data.response_document?.response_code} />
          <span className="tag is-dark">
            {props.data.request_document?.verb}
          </span>
          <span className="title is-spaced">
            {props.data.request_document?.url}
          </span>
          <span className="subtitle push-right">
            {moment(props.data.call_timestamp).format('L LTS')}
            <span className="tiny-text">({t('common.localTime')})</span>
          </span>
          <Link to={'/' + props.data.id + location.search}>
            <span role="button" className="subtitle">
              <img className="icon-16" src="assets/icons/open-new-tab.svg" />
            </span>
          </Link>
        </div>
        <ContextBlock context={props.data.service_context} />
        <CallDetails data={props.data} expanded={expanded} />
      </div>
    </div>
  );
}
